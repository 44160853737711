































































import { errorNotification } from "@/includes/NotificationService";
import BindChannelByCode, { BindChannelState } from "@/components/chat/BindChannelByCode.vue";
import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { CHATKEEPER_BOT_LOGIN } from "@/includes/constants";
import BindChannelApi from "@/includes/Api/BindChannel.api";

import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";

import { Component, Emit, Mixins, VModel } from 'vue-property-decorator';

enum ActiveBindMethod {
  Self = 'self',
  Code = 'code'
}

@Component({
  data() {
    return {
      ActiveBindMethod
    }
  },
  components: {
    BindChannelByCode,
    TextInput,
    PiTabs
  }
})
export default class BindChannel extends Mixins(InputSetups, TariffsTagsHelper) {
  @VModel({ type: Boolean, required: true, default: false }) isBindModalOpen!: boolean

  activeMethod: ActiveBindMethod = ActiveBindMethod.Self

  channelId = ''

  @Emit()
  onBind(channelId: string | number) {
    if (channelId) {
      if (typeof channelId === 'string') {
        this.isBindModalOpen = false

        return Number.parseInt(channelId)
      }

      this.isBindModalOpen = false
      return channelId
    }
  }

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('bind_channel_self').toString(),
        value: ActiveBindMethod.Self,
        icon: {
          name: 'link'
        }
      },
      {
        label: this.$t('bind_channel_code').toString(),
        value: ActiveBindMethod.Code,
        icon: {
          name: 'contact_mail'
        }
      }
    ]
  }

  checkChannelValue() {
    BindChannelApi.checkBindChannel('tg', {
      chat_id: this.$store.getters.chatId,
      channel: this.channelId.trim()
    })
      .then(res => {
        this.onBind(res.data.id)
      })
      .catch(errorNotification)
  }

  get chat() {
    return this.$store.state.chatState.chat
  }

  get botName(): string {
    if (this.brandTag) {
      return atSignedLogin(CHATKEEPER_BOT_LOGIN)
    } else {
      return atSignedLogin(this.chat?.brand?.botname ?? CHATKEEPER_BOT_LOGIN)
    }
  }

  handleOnChannelBind(data: BindChannelState) {
    if (data.binded && data.channel_id) {
      this.onBind(data.channel_id)
    }
  }
}
