import { render, staticRenderFns } from "./BindChannel.vue?vue&type=template&id=546c2f36&scoped=true&"
import script from "./BindChannel.vue?vue&type=script&lang=ts&"
export * from "./BindChannel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546c2f36",
  null
  
)

export default component.exports