

























































































































































import NewUsersHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/NewUsersHandlerModuleBuilder";
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import {
  GreetingHandleTypeEnum,
  GreetingProtectionTypeEnum
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/types";
import {
  BindedChannelGreetingProtection,
  FormValidationGreetingProtection,
  InviteValidationGreetingProtection,
  MathCaptchaGreetingProtection,
  NoneGreetingProtection,
  StandardGreetingProtection,
  VariableCaptchaGreetingProtection,
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection";
import BindedChannelGreetingProtectionView from "./components/ProtectionTypes/BindedChannelGreetingProtectionView.vue";
import FormValidationGreetingProtectionView
  from "./components/ProtectionTypes/FormValidationGreetingProtectionView.vue";
import InviteValidationGreetingProtectionView
  from "./components/ProtectionTypes/InviteValidationGreetingProtectionView.vue";
import MathCaptchaGreetingProtectionView from "./components/ProtectionTypes/MathCaptchaGreetingProtectionView.vue";
import NoneGreetingProtectionView from "./components/ProtectionTypes/NoneGreetingProtectionView.vue";
import StandardGreetingProtectionView from "./components/ProtectionTypes/StandartGreetingProtectionView.vue";
import VariableCaptchaGreetingProtectionView
  from "./components/ProtectionTypes/VariableCaptchaGreetingProtectionView.vue";
import Placeholders from "@/mixins/placeholders/placeholders";

import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator'
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import FileUrlList from "piramis-base-components/src/components/File/FileUrlsList.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import ExternalValidationGreetingProtection
  from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection/ExternalValidationGreetingProtection";
import ExternalValidationProtectionView
  from "@/components/Modules/modules/Greeting/components/ProtectionTypes/ExternalValidationProtectionView.vue";

@Component({
  data() {
    return {
      BindedChannelGreetingProtection,
      FormValidationGreetingProtection,
      InviteValidationGreetingProtection,
      MathCaptchaGreetingProtection,
      NoneGreetingProtection,
      StandardGreetingProtection,
      VariableCaptchaGreetingProtection,
      MediaEditorMode,
      ExternalValidationGreetingProtection,
      GreetingProtectionTypeEnum,
      ModuleManagerState
    }
  },
  components: {
    ExternalValidationProtectionView,
    MultiMessageEditorWithMediaInput,
    SwitchInput,
    SelectInput,
    FileUrlList,
    CenteredColumnLayout,
    NestedContent,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    BindedChannelGreetingProtectionView,
    FormValidationGreetingProtectionView,
    InviteValidationGreetingProtectionView,
    MathCaptchaGreetingProtectionView,
    NoneGreetingProtectionView,
    StandardGreetingProtectionView,
    VariableCaptchaGreetingProtectionView,
    ConfigField
  },
})
export default class NewUsersHandlerModuleView extends Mixins(ModuleBindings, TariffsTagsHelper, InputSetups, Placeholders) {
  @VModel() module!: NewUsersHandlerModuleBuilder

  private greetingHandleTypeOpts: Array<SelectOptionData> = [
    {
      label: this.$t('greeting_handle_type_enabled').toString(),
      value: GreetingHandleTypeEnum.Enabled
    },
    {
      label: this.$t('greeting_handle_type_disabled').toString(),
      value: GreetingHandleTypeEnum.Disabled
    },
    {
      label: this.$t('greeting_handle_type_default').toString(),
      value: GreetingHandleTypeEnum.Default,
      disabled: true
    },
  ]

  get greetingHandleTypeOptions():Array<SelectOptionData> {
    return this.greetingHandleTypeOpts
  }

  set greetingHandleTypeOptions(options:Array<SelectOptionData>) {
    this.greetingHandleTypeOpts = options
  }

  get greetingProtectionTypeOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('greeting_protection_type_none').toString(),
        value: GreetingProtectionTypeEnum.None,
      },
      {
        label: this.$t('greeting_protection_type_standard').toString(),
        value: GreetingProtectionTypeEnum.Standart,
      },
      {
        label: this.$t('greeting_protection_type_invitevalidation').toString(),
        value: GreetingProtectionTypeEnum.InviteValidation,
      },
      {
        label: this.$t('greeting_protection_type_formvalidation').toString(),
        value: GreetingProtectionTypeEnum.FormValidation,
        tags: this.getTagsByFieldKey(`binded_channel`),
        disabled: !!this.getTagsByFieldKey(`binded_channel`)
      },
      {
        label: this.$t('greeting_protection_type_variablecaptcha').toString(),
        value: GreetingProtectionTypeEnum.VariableCaptcha,
        tags: this.getTagsByFieldKey(`binded_channel`),
        disabled: !!this.getTagsByFieldKey(`binded_channel`)
      },
      {
        label: this.$t('greeting_protection_type_mathcaptcha').toString(),
        value: GreetingProtectionTypeEnum.MathCaptcha,
        tags: this.getTagsByFieldKey(`binded_channel`),
        disabled: !!this.getTagsByFieldKey(`binded_channel`)
      },
      {
        label: this.$t('greeting_protection_type_bindedchannel').toString(),
        value: GreetingProtectionTypeEnum.BindedChannel,
        tags: this.getTagsByFieldKey(`binded_channel`),
        disabled: !!this.getTagsByFieldKey(`binded_channel`)
      },
      {
        label: this.$t('greeting_protection_type_external_validation').toString(),
        value: GreetingProtectionTypeEnum.ExternalValidation,
        tags: this.ultimateLicenseTag,
        disabled: !!this.ultimateLicenseTag
      },

    ]
  }

  created() {
    if (this.module.model.params.greeting_handle_type !== GreetingHandleTypeEnum.Default) {
      this.greetingHandleTypeOptions = this.greetingHandleTypeOptions.filter(opt => opt.value !== GreetingHandleTypeEnum.Default)
    }
  }
}
