
















import {
  NoneGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection";
import { InputSetups } from "@/mixins/input-setups";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    SwitchInput
  }
})
export default class NoneGreetingProtectionView extends Mixins(InputSetups) {
  @VModel() model!: NoneGreetingProtection
}
