var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"variable-captcha-greeting-protection-view"},[_c('chips-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'variants',
        'prefix': 'variable_captcha_',
        'validation': 'required',
        'hasAnchor': true,
      }
    }}}),_c('select-input',{staticClass:"w-full",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'variant',
        'prefix': 'variable_captcha_',
        'options': _vm.variantsOptions,
        'multiple': false,
        'validation': 'required',
        'hasAnchor': true,
      },
    }}}),_c('base-protection-type-fields',{attrs:{"protection-type":_vm.model.type,"ban-unverified-users-time":_vm.model.ban_unverified_users_time,"remove-not-confirm-users-timeout":_vm.model.remove_not_confirm_users_timeout,"remove-hellow-after-button-pressed":_vm.model.remove_hellow_after_button_pressed,"remove-hellow-button":_vm.model.remove_hellow_button,"strict_mode":_vm.model.mode},on:{"update:banUnverifiedUsersTime":function($event){return _vm.$set(_vm.model, "ban_unverified_users_time", $event)},"update:ban-unverified-users-time":function($event){return _vm.$set(_vm.model, "ban_unverified_users_time", $event)},"update:removeNotConfirmUsersTimeout":function($event){return _vm.$set(_vm.model, "remove_not_confirm_users_timeout", $event)},"update:remove-not-confirm-users-timeout":function($event){return _vm.$set(_vm.model, "remove_not_confirm_users_timeout", $event)},"update:removeHellowAfterButtonPressed":function($event){return _vm.$set(_vm.model, "remove_hellow_after_button_pressed", $event)},"update:remove-hellow-after-button-pressed":function($event){return _vm.$set(_vm.model, "remove_hellow_after_button_pressed", $event)},"update:removeHellowButton":function($event){return _vm.$set(_vm.model, "remove_hellow_button", $event)},"update:remove-hellow-button":function($event){return _vm.$set(_vm.model, "remove_hellow_button", $event)},"update:strict_mode":function($event){return _vm.$set(_vm.model, "mode", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }