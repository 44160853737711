











import { Vue, Component, VModel } from 'vue-property-decorator';
import BaseProtectionTypeFields
  from "@/components/Modules/modules/Greeting/components/ProtectionTypes/BaseProtectionTypeFields.vue";
import ExternalValidationGreetingProtection
  from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection/ExternalValidationGreetingProtection";

@Component({
  components: {
    BaseProtectionTypeFields
  }
})
export default class ExternalValidationProtectionView extends Vue {
  @VModel() model!: ExternalValidationGreetingProtection

}
