var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-validation-greeting-protection-view"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'form',
        'prefix': 'form_validation_',
        'taggable': true,
        'options': _vm.getForms,
        'clearable': true,
        'createButton': {
          'title': this.$t('form_create_new_form'),
          'callback': _vm.goToForms,
        },
        'hasAnchor': true,
      }
    }}}),(!!_vm.model.form)?_c('nested-content',[_c('config-field',{attrs:{"title":_vm.$t('field_enter_protection_form_users_title'),"mini-help-message":_vm.$t('field_enter_protection_form_users_mini_help_message')}},[_c('add-admin',{attrs:{"module-guid":_vm.moduleGuid,"add-users-disabled":_vm.addUsersDisabled,"add-type":_vm.AddAdminType.Enter,"users-path":{
          model: _vm.model,
          key: 'users'
        }}})],1)],1):_vm._e(),_c('switch-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'admin_validation',
        'prefix': 'form_validation_',
        'hasAnchor': true,
      }
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'button_text',
        'prefix': 'standart_',
        'validation': 'required',
        'hasAnchor': true,
      }
    }}}),_c('base-protection-type-fields',{attrs:{"protection-type":_vm.model.type,"ban-unverified-users-time":_vm.model.ban_unverified_users_time,"remove-not-confirm-users-timeout":_vm.model.remove_not_confirm_users_timeout,"remove-hellow-after-button-pressed":_vm.model.remove_hellow_after_button_pressed,"remove-hellow-button":_vm.model.remove_hellow_button,"strict_mode":_vm.model.mode},on:{"update:banUnverifiedUsersTime":function($event){return _vm.$set(_vm.model, "ban_unverified_users_time", $event)},"update:ban-unverified-users-time":function($event){return _vm.$set(_vm.model, "ban_unverified_users_time", $event)},"update:removeNotConfirmUsersTimeout":function($event){return _vm.$set(_vm.model, "remove_not_confirm_users_timeout", $event)},"update:remove-not-confirm-users-timeout":function($event){return _vm.$set(_vm.model, "remove_not_confirm_users_timeout", $event)},"update:removeHellowAfterButtonPressed":function($event){return _vm.$set(_vm.model, "remove_hellow_after_button_pressed", $event)},"update:remove-hellow-after-button-pressed":function($event){return _vm.$set(_vm.model, "remove_hellow_after_button_pressed", $event)},"update:removeHellowButton":function($event){return _vm.$set(_vm.model, "remove_hellow_button", $event)},"update:remove-hellow-button":function($event){return _vm.$set(_vm.model, "remove_hellow_button", $event)},"update:strict_mode":function($event){return _vm.$set(_vm.model, "mode", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }