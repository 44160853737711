var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"binded-channel-greeting-protection-view"},[(_vm.$store.state.chatState.chat.binded_channel && _vm.$store.state.chatState.chat.binded_channel !== '')?_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-left font-medium"},[_vm._v(" "+_vm._s(_vm.$t('field_channel_binding_channel_name_title'))+" ")]),_c('p',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.$store.state.chatState.chat.binded_channel)+" ")])]),_c('a-button',{staticClass:"m-2 w-full md:w-auto",attrs:{"type":"danger"},on:{"click":_vm.unbindChannel}},[_vm._v(" "+_vm._s(_vm.$t('channel_binding_unbind'))+" ")])],1):_c('div',{staticClass:"text-center"},[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('bind_channel_pre_alert_greeting')}}),_c('a-button',{staticClass:"my-2",attrs:{"type":"primary"},on:{"click":function($event){_vm.isBindModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('bind_channel'))+" ")])],1),_c('bind-channel',{on:{"on-bind":_vm.doBindChannel},model:{value:(_vm.isBindModalOpen),callback:function ($$v) {_vm.isBindModalOpen=$$v},expression:"isBindModalOpen"}}),_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'invite_link',
        'prefix': 'binded_channel_',
        'allow-clear': true,
        'hasAnchor': true,
      }
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'button_text',
        'prefix': 'standart_',
        'validation': 'required',
        'hasAnchor': true,
      }
    }}}),_c('base-protection-type-fields',{attrs:{"protection-type":_vm.model.type,"ban-unverified-users-time":_vm.model.ban_unverified_users_time,"remove-not-confirm-users-timeout":_vm.model.remove_not_confirm_users_timeout,"remove-hellow-after-button-pressed":_vm.model.remove_hellow_after_button_pressed,"remove-hellow-button":_vm.model.remove_hellow_button,"strict_mode":_vm.model.mode},on:{"update:banUnverifiedUsersTime":function($event){return _vm.$set(_vm.model, "ban_unverified_users_time", $event)},"update:ban-unverified-users-time":function($event){return _vm.$set(_vm.model, "ban_unverified_users_time", $event)},"update:removeNotConfirmUsersTimeout":function($event){return _vm.$set(_vm.model, "remove_not_confirm_users_timeout", $event)},"update:remove-not-confirm-users-timeout":function($event){return _vm.$set(_vm.model, "remove_not_confirm_users_timeout", $event)},"update:removeHellowAfterButtonPressed":function($event){return _vm.$set(_vm.model, "remove_hellow_after_button_pressed", $event)},"update:remove-hellow-after-button-pressed":function($event){return _vm.$set(_vm.model, "remove_hellow_after_button_pressed", $event)},"update:removeHellowButton":function($event){return _vm.$set(_vm.model, "remove_hellow_button", $event)},"update:remove-hellow-button":function($event){return _vm.$set(_vm.model, "remove_hellow_button", $event)},"update:strict_mode":function($event){return _vm.$set(_vm.model, "mode", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }